<template>
  <div class="agencyactive">
    <Top></Top>
    <Fix></Fix>
    <div class="agencyactive-box1" v-if="ident != '2'">
        <div>
          <div v-for="(v,k) in couponlist">
              <img :src="require('/static/images/dis_yes1.png')" alt="" v-if="v.is_get == 1" @click="getcouponrec(v.id)">
              <img :src="require('/static/images/dis_no1.png')" alt="" v-if="v.is_get == 2">
              <div class="box-div1" v-if="v.is_get == 1">{{v.user_total}}</div>
              <div class="box-div1-no" v-if="v.is_get == 2">{{v.user_total}}</div>
              <div class="box-div2">
                <div>{{v.remark}}</div>
                <div>满{{v.total}}可用</div>
              </div>
          </div>
        </div>
    </div>
    <div class="agencyactive-box2" v-else>
        <div>
          <div v-for="(v,k) in couponlist">
              <img :src="require('/static/images/dis_yes2.png')" alt="" v-if="v.is_get == 1" @click="getcouponrec(v.id)">
              <img :src="require('/static/images/dis_no2.png')" alt="" v-if="v.is_get == 2">
              <div class="box-div3">{{v.user_total}}</div>
              <div class="box-div4">
                <div>{{v.remark}}</div>
                <div>满{{v.order_num}}件,立减￥{{v.user_total}}元/件</div>
              </div>
          </div>
        </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'agencyactive',
  data(){
    return{
      ident:this.$cookie.getCookie('user_type'),
      couponlist:[]
    }
  },
  components: {
    Top,
    Foot,
    Fix
  },
  methods:{
    getcoupon(){
      this.$http.post(this.GLOBAL.base_url+'/api/coupon/getDeCoupon',{
        user_id:this.$cookie.getCookie('u_id')
      }).then(res=>{
        // console.log(res)
              if(res.data.code == "1"){
                this.couponlist = res.data.data;
              }
            })
    },
    getcouponrec(id){
      this.$http.post(this.GLOBAL.base_url+'/api/coupon/receiveCoupon',{
        user_id:this.$cookie.getCookie('u_id'),
        c_id:id
      }).then(res=>{
        // console.log(res)
              if(res.data.code == "1"){
                this.getcoupon()
              }
            })
    }
  },
  mounted(){
    this.getcoupon();
  }
}

</script>

<style scoped>
  .agencyactive-box1{
    width: 100%;
    height:3710px;
    background-size:cover;
    background: url("../../../static/images/ele1.png") no-repeat center top;
    position: relative;
  }
  .agencyactive-box1>div{
    width: 1130px;
    height: 305px;
    /* border:1px solid black; */
    position: absolute;
    top:1460px;
    left:50%;
    transform: translate(-50%,0);
    padding: 70px 0 40px 30px;
  }
  .agencyactive-box1>div>div{
    float: left;
    position: relative;
    /* border:1px solid red; */
  }
  .agencyactive-box1>div>div>img{
    width: 564px;
    height: 152px;
    cursor: pointer;
  }
  .box-div1{
    position: absolute;
    top:30px;
    left: 56px;
    color: #C31E1E;
    font-size: 56px;
    letter-spacing: -5px;
  }
  .box-div1-no{
    position: absolute;
    top:30px;
    left: 56px;
    color: gray;
    font-size: 56px;
    letter-spacing: -5px;
  }
  .box-div2{
    position: absolute;
    top:35px;
    left: 198px;
  }
  .box-div2>div:nth-of-type(1){
    width: 260px;
    height: 28px;
    font-size: 20px;
    margin-bottom: 5px;
    letter-spacing: 2px;
    overflow: hidden;    
    text-overflow:ellipsis;    
    white-space: nowrap;  
    color: #FFFFFF;
  }
  .box-div2>div:nth-of-type(2){
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    /* opacity: 80%; */
  }
  .agencyactive-box2{
    width: 100%;
    height:2537px;
    background-size:cover;
    background: url("../../../static/images/ele2.png") no-repeat center top;
    position: relative;
  }
  .agencyactive-box2>div{
    width: 1130px;
    height: 305px;
    /* border:1px solid black; */
    position: absolute;
    top:1435px;
    left:50%;
    transform: translate(-50%,0);
    padding: 70px 0 40px 30px;
  }
  .agencyactive-box2>div>div{
    float: left;
    position: relative;
    /* border:1px solid red; */
  }
  .agencyactive-box2>div>div>img{
    width: 564px;
    height: 152px;
    cursor: pointer;
  }
  .box-div3{
    width: 72px;
    text-align: center;
    position: absolute;
    top:50px;
    left: 44px;
    color: #C31E1E;
    font-size: 44px;
    letter-spacing: -5px;
  }
  .box-div4{
    position: absolute;
    top:45px;
    left: 195px;
  }
  .box-div4>div:nth-of-type(1){
    width: 220px;
    height: 28px;
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 540;
    overflow: hidden;    
    text-overflow:ellipsis;    
    white-space: nowrap;  
    color: #54231D;
  }
  .box-div4>div:nth-of-type(2){
    font-size: 18px;
    font-weight: 400;
    color: #54231D;
    line-height: 25px;
    /* opacity: 80%; */
  }
</style>
